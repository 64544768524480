/* eslint-disable react/no-danger */
/* eslint-disable react/no-unknown-property */
/* eslint-disable import/prefer-default-export */
import React from 'react';

export const IconShipping = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    width="24px"
    viewBox="0 0 24 24"
  >
    <defs>
      <style dangerouslySetInnerHTML={{
        __html: `
          .lj-round {
            stroke-linejoin: round;
            stroke-miterlimit: 10;
          }
        `,
      }}
      />
    </defs>
    <g>
      <polygon
        fill="none"
        stroke="#1D64CC"
        strokeWidth="1.25"
        points="1.2,5.6
        1.2,17.9 12.2,23 12.2,10.2"
        className="lj-round"
      />
      <polygon
        fill="none"
        stroke="#1D64CC"
        strokeWidth="1.25"
        className="lj-round"
        points="23.2,5.6
        23.2,17.9 12.2,23 12.2,10.2"
      />
      <polygon
        fill="none"
        stroke="#1D64CC"
        strokeWidth="1.25"
        className="lj-round"
        points="12.2,10
        1.2,5.6 12.2,1 23.2,5.6"
      />
      <polyline
        fill="none"
        stroke="#1D64CC"
        strokeWidth="1.25"
        strokeLinecap="round"
        className="lj-round"
        points="
        17.7,3.3 6.7,7.9 6.7,13.1 "
      />
      <polygon
        fill="none"
        stroke="#1D64CC"
        className="lj-round"
        points="20.4,9.9 20.4,14.4 14.9,16.8
        14.9,12.2 "
      />
    </g>
  </svg>
);
