/* eslint-disable import/prefer-default-export */
import React from 'react';

export const IconCafe = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    width="24px"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="1.75"
  >
    <g>
      <path
        fill="none"
        d="M20.6,8.4h-0.7l0.4-2.7h0.3
        c0.8,0,1.4-0.7,1.3-1.5l-0.3-2c-0.1-0.6-0.6-1.1-1.3-1.1H3.7c-0.6,0-1.1,0.4-1.3,1L2.1,4.1C1.9,4.9,2.6,5.7,3.4,5.7h0.3l0.4,2.7
        H3.3C2.5,8.4,1.9,9.2,2,10l1.3,6.5c0.1,0.6,0.7,1.1,1.3,1.1h0.7l0.5,3.8c0.1,0.8,0.7,1.4,1.4,1.4h9.4c0.7,0,1.3-0.6,1.4-1.4
        l0.5-3.8h0.7c0.6,0,1.2-0.4,1.3-1.1l1.3-6.5C22.1,9.2,21.5,8.4,20.6,8.4z"
      />
      <line fill="none" x1="4.1" y1="8.4" x2="19.9" y2="8.4" />
      <line fill="none" x1="18.6" y1="17.6" x2="5.3" y2="17.6" />
      <line fill="none" x1="3.7" y1="5.7" x2="20.2" y2="5.7" />
    </g>
  </svg>
);
