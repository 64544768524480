import React from 'react';

import { ThumbsUp, PlusSquare, BookOpen } from 'react-feather';
import { IconCafe, IconSponsors } from './icons';
import Link from './link';

const NavLinks = ({ closed }) => (
  <nav className={`linkList ${closed ? '' : 'open'}`}>
    <ul>
      <li>
        <Link
          to="/fund"
        >
          <ThumbsUp />
          <span>Support The Fund</span>
        </Link>
      </li>
      <li>
        <Link to="/cafes" className="hi">
          <IconCafe />
          <span>Find A Cafe</span>
        </Link>
      </li>
      <li>
        <Link
          to="https://fellow.typeform.com/to/EOsd5Q"
          target="_blank"
        >
          <PlusSquare />
          <span>Add A Cafe</span>
        </Link>
      </li>
      <li>
        <Link
          to="/blog"
        >
          <BookOpen />
          <span>Read The Blog</span>
        </Link>
      </li>
      <li>
        <Link
          to="/sponsors"
        >
          <IconSponsors />
          <span>Our Sponsors</span>
        </Link>
      </li>
    </ul>
  </nav>
);

export default NavLinks;
