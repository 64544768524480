import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { getImageUrl } from 'takeshape-routing';

import routes from '../routes';
import Link from './link';

import { IconCT } from './icons';
import NavLinks from './navLinks';

const Menu = (props, { location }) => (
  <nav className="menu">
    <div className="menu__header x">
      <div className="site-name rel z0 mb15">
        <h1>
          <IconCT />
          {' '}
          ComeTogether
        </h1>
        <Link to="/" className="abs fill z2" />
      </div>
      <section className="desktopNav">
        <div className="menu__search rel x">
          <form>
            <input
              type="text"
              aria-label="Search"
              placeholder="Where's the cafe you love?"
              onChange={props.handleInputChange}
            />
            {/* Todo: Andy - remove the link here, add global search state. */}
            <div className="btn rel">
              →
              <Link to="/cafes" className="abs fill z2" />
            </div>
          </form>
        </div>
        <NavLinks />
      </section>
    </div>
    <div className="site__credits x jcb">
      <span>With love –</span>
      <div className="logos">
        <a
          href="https://fellowproducts.com"
          className="credits__logo fellowLogo"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="u-screenReader">Fellow Products</span>
          <svg
            id="Fellow Logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 390.36 74.16"
          >
            <g>
              <path
                fill="rgb(57,102,174)"
                d="M311.5,140.83c-.57,0-10.56,3.95-14.38,5.48-3.84-1.53-13.82-5.48-14.4-5.48a1.25,1.25,0,0,0-1.26,1.25v12.16a1.25,1.25,0,0,0,1.26,1.26c.58,0,10.56-4,14.4-5.48,3.82,1.52,13.81,5.48,14.38,5.48a1.25,1.25,0,0,0,1.26-1.26V142.08a1.24,1.24,0,0,0-1.26-1.25"
                transform="translate(-26.45 -81.34)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M59.47,83.12H28.29A1.85,1.85,0,0,0,26.45,85v46.45a1.84,1.84,0,0,0,1.84,1.84h3.09a1.83,1.83,0,0,0,1.83-1.84v-20.9h16a1.84,1.84,0,0,0,1.84-1.84v-2.76a1.84,1.84,0,0,0-1.84-1.84h-16V89.55H59.47a1.84,1.84,0,0,0,1.85-1.83V85a1.85,1.85,0,0,0-1.85-1.84"
                transform="translate(-26.45 -81.34)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M125.21,89.55A1.83,1.83,0,0,0,127,87.72V85a1.83,1.83,0,0,0-1.83-1.84H93.29A1.83,1.83,0,0,0,91.46,85v46.45a1.84,1.84,0,0,0,1.83,1.84h31.92a1.84,1.84,0,0,0,1.83-1.84v-2.84a1.83,1.83,0,0,0-1.83-1.83H98.14V110.5H114a1.84,1.84,0,0,0,1.84-1.83v-2.76a1.84,1.84,0,0,0-1.84-1.84H98.14V89.55Z"
                transform="translate(-26.45 -81.34)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M189.17,126.74H165V85a1.85,1.85,0,0,0-.54-1.3,1.81,1.81,0,0,0-1.3-.54h-3A1.85,1.85,0,0,0,158.34,85v46.45a1.83,1.83,0,0,0,1.84,1.83h29a1.83,1.83,0,0,0,1.84-1.83v-2.84a1.84,1.84,0,0,0-1.84-1.83"
                transform="translate(-26.45 -81.34)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M248.93,126.74H224.77V85a1.84,1.84,0,0,0-1.83-1.84h-3A1.84,1.84,0,0,0,218.1,85v46.45a1.82,1.82,0,0,0,1.83,1.83h29a1.82,1.82,0,0,0,1.83-1.83v-2.84a1.83,1.83,0,0,0-1.83-1.83"
                transform="translate(-26.45 -81.34)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M297.16,81.34A26.88,26.88,0,1,0,324,108.26a26.95,26.95,0,0,0-26.84-26.92m0,47.3a20.42,20.42,0,1,1,20.37-20.38,20.44,20.44,0,0,1-20.37,20.38"
                transform="translate(-26.45 -81.34)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M416,84.66l-2.17-1.49a1.83,1.83,0,0,0-1.54-.25,1.8,1.8,0,0,0-1.18,1l-15.33,35.41L380.44,84.05a1.82,1.82,0,0,0-.64-.78l-.11-.07a2,2,0,0,0-1.93-.09,1.82,1.82,0,0,0-.86.91l-15.29,35.35L346.21,84a1.85,1.85,0,0,0-1-1,1.79,1.79,0,0,0-1.45.07l-2.24,1.09a1.83,1.83,0,0,0-.88,2.38L359.86,131a1.81,1.81,0,0,0,1.67,1.11h0a1.84,1.84,0,0,0,1.68-1.1L378.7,95.55,394,131a1.85,1.85,0,0,0,1.68,1.11h0a1.87,1.87,0,0,0,1.69-1.1l19.25-44.09a1.84,1.84,0,0,0-.65-2.25"
                transform="translate(-26.45 -81.34)"
              />
            </g>
          </svg>
        </a>
        <a
          href="https://wearemage.com"
          className="credits__logo mageLogo"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="u-screenReader">Mage</span>
          <svg
            version="1.1"
            id="Mage Logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 376.99 164"
          >
            <g>
              <g>
                <path
                  fill="rgb(57,102,174)"
                  d="M354.66,166.65a2.09,2.09,0,0,1-.43,0,2,2,0,0,1-1.52-2.38c0-.12,1.43-7.16-2.75-16.11-4.44-9.52-20.55-33.16-20.72-33.4a1.78,1.78,0,0,1-.24-.5l-7.17-21.58a13.33,13.33,0,0,1-.55-2.47,2.28,2.28,0,0,1,0-.26l.07-17.56a9.71,9.71,0,0,1,.39-2.7l5.14-18.35a3.93,3.93,0,0,1,2.05-2.49,3.79,3.79,0,0,1,3.16-.07c4.4,1.87,6.4,6.55,1.83,23.36a3.51,3.51,0,0,0-.07,1.37L335.07,87a8.87,8.87,0,0,0,.63,1.72c2.54,5.2,5,10.53,5.52,14.26a2,2,0,0,1-.53,1.66,15.2,15.2,0,0,0-1.86,2.44,2,2,0,1,1-3.51-1.92,15.78,15.78,0,0,1,1.77-2.49c-.83-3.7-3.6-9.36-5-12.2a12.7,12.7,0,0,1-1-2.67,2.74,2.74,0,0,1,0-.28l-1.25-13.6a7.46,7.46,0,0,1,.19-2.82c4.36-16,1.78-18,.64-18.55l-5.1,18.22a5.31,5.31,0,0,0-.25,1.62l-.07,17.45a9.65,9.65,0,0,0,.37,1.61l7.07,21.3c1.8,2.64,16.49,24.32,20.88,33.73,4.8,10.28,3.11,18.32,3,18.65A2,2,0,0,1,354.66,166.65Z"
                  transform="translate(-31.94 -2.65)"
                />
                <path
                  fill="rgb(57,102,174)"
                  d="M350.48,90.57a2,2,0,0,1-1.91-1.4l-2.21-7a2,2,0,0,1,.21-1.65A59.81,59.81,0,0,0,353,66.63,2,2,0,0,1,354,65.35a57.35,57.35,0,0,0,12.26-8.44c4.79-4.43,3.43-9,3.37-9.22a1.9,1.9,0,0,0-.21-.43,5.33,5.33,0,0,0-1.12,1.08c-2.74,3.36-10.93,8.16-18.39,12.4L348,61.85c-2.13,1.23-5.8,10.86-7.37,15C340,78.5,339.41,80,339,80.89a2,2,0,1,1-3.66-1.62c.37-.84.91-2.25,1.53-3.88,3.15-8.24,6-15.2,9.11-17l2-1.12c4.8-2.73,14.77-8.4,17.27-11.46,1.66-2,3.35-2.89,5-2.56,2.31.45,3.14,3,3.23,3.24s2.27,7.12-4.47,13.36a60.8,60.8,0,0,1-12.43,8.69,65.6,65.6,0,0,1-6.11,13.29L352.38,88a2,2,0,0,1-1.3,2.51A2.13,2.13,0,0,1,350.48,90.57Z"
                  transform="translate(-31.94 -2.65)"
                />
                <path
                  fill="rgb(57,102,174)"
                  d="M342,60.72a2,2,0,0,1-2-1.62c-.5-2.53-3.44-26.14-1.22-30.89,4.9-10.51,6.13-15.42,6.3-17.69.07-.93.29-3.79,2.44-4.92a4,4,0,0,1,4,.29,4.74,4.74,0,0,1,.94.73A16,16,0,0,1,356.65,21c-1.41,6.47-2.94,11.4-3.47,13.06a88.1,88.1,0,0,1,2.62,16.57,2,2,0,0,1-4,.24,84,84,0,0,0-2.64-16.31,2,2,0,0,1,0-1.2,133,133,0,0,0,3.55-13.21,11.84,11.84,0,0,0-3-10.69l-.12-.1-.14-.07a5.23,5.23,0,0,0-.3,1.56c-.28,3.77-2.53,10.19-6.67,19.07C341.18,32.69,343,53,344,58.33a2,2,0,0,1-1.58,2.35A2.45,2.45,0,0,1,342,60.72Z"
                  transform="translate(-31.94 -2.65)"
                />
                <path
                  fill="rgb(57,102,174)"
                  d="M361.06,82.88a2.28,2.28,0,0,1-.44,0,2,2,0,0,1-1.51-2.4c.38-1.7-1-6.52-2.06-9.4a2,2,0,1,1,3.74-1.41c.51,1.34,3,8.23,2.22,11.7A2,2,0,0,1,361.06,82.88Z"
                  transform="translate(-31.94 -2.65)"
                />
                <path
                  fill="rgb(57,102,174)"
                  d="M355.88,91.7a2,2,0,0,1-1.62-3.16c2.18-3.07,8.94-6.77,13.91-7.62a2,2,0,0,1,.67,3.94c-4.25.73-9.87,4-11.33,6A2,2,0,0,1,355.88,91.7Z"
                  transform="translate(-31.94 -2.65)"
                />
                <path
                  fill="rgb(57,102,174)"
                  d="M363.54,46.6a2,2,0,0,1-2-1.56c-.11-.52-.4-1.45-.71-2.43-1.36-4.33-2.35-7.81-1.68-10,.49-1.65,1.38-5.3,1.87-7.32a16.21,16.21,0,0,0,.45-4.16c-.07-3-.79-4.66-2.15-5-.27-.07-.31-.08-.45,0s-1.26,1-2.18,4.47a2,2,0,0,1-3.87-1c1-3.64,2.29-5.9,4.06-6.92a4.42,4.42,0,0,1,3.47-.4c2.27.62,5,2.64,5.12,8.8a19.7,19.7,0,0,1-.56,5.19c-.5,2.07-1.41,5.8-1.93,7.54-.37,1.22,1.05,5.75,1.66,7.68.35,1.11.65,2.07.81,2.75A2,2,0,0,1,364,46.55,2.2,2.2,0,0,1,363.54,46.6Z"
                  transform="translate(-31.94 -2.65)"
                />
                <path
                  fill="rgb(57,102,174)"
                  d="M376,130.17a2,2,0,0,1-2-2c0-14.1-21.92-32.19-22.15-32.37a2,2,0,1,1,2.54-3.1c1,.79,23.61,19.49,23.61,35.47A2,2,0,0,1,376,130.17Z"
                  transform="translate(-31.94 -2.65)"
                />
                <path
                  fill="rgb(57,102,174)"
                  d="M369.93,78.78a2,2,0,0,1-2-1.94c-.08-2.72-1.94-8.8-2.65-10.92a2,2,0,0,1,3.79-1.28c.28.84,2.75,8.28,2.85,12.09a2,2,0,0,1-1.94,2Z"
                  transform="translate(-31.94 -2.65)"
                />
                <path
                  fill="rgb(57,102,174)"
                  d="M390.68,111.63a2,2,0,0,1-1.65-.87,64.51,64.51,0,0,1-6.09-12.58C378,85.27,372.8,83.26,372.75,83.24a2,2,0,0,1-1.31-2.49A2,2,0,0,1,374,79.43c.71.21,7,2.51,12.71,17.32a62.67,62.67,0,0,0,5.65,11.74,2,2,0,0,1-1.64,3.14Z"
                  transform="translate(-31.94 -2.65)"
                />
                <path
                  fill="rgb(57,102,174)"
                  d="M396.24,162.6a2,2,0,0,1-2-2c0-4,3.49-9.84,6.61-15,1.16-1.9,2.25-3.7,2.85-4.95a10.35,10.35,0,0,0,.87-2.64c.55-2.89.34-9.43.16-15.2-.1-2.91-.19-5.66-.19-7.95,0-8.1-4.62-17.26-4.67-17.35a2.55,2.55,0,0,1-.18-.53,161.61,161.61,0,0,0-7.15-24.48,6.26,6.26,0,0,1-.45-2.23c0-.14,0-.29,0-.43s0-.14,0-.21l.21-18.13a2.3,2.3,0,0,0,0-.54,3.37,3.37,0,0,0-1.23.24,1.8,1.8,0,0,1-.33.1h0s-3.4,1.07-5.53,8.29c-1.55,5.27-2.07,12.48.26,16.69,1.91,3.46,2.31,16.7,2.27,17.29a49.49,49.49,0,0,1-.51,5.21,2,2,0,1,1-3.93-.71h0s.24-1.43.45-4.75c.07-1.5-.63-13-1.78-15.1-3.09-5.58-2.33-13.87-.6-19.76,2.6-8.83,7.17-10.68,8.3-11,.74-.29,3.11-1.05,4.95.21a4.26,4.26,0,0,1,1.71,3.92l-.21,18c0,.15,0,.29,0,.44a3.27,3.27,0,0,0,.12.91,164.92,164.92,0,0,1,7.35,25c.82,1.65,5,10.54,5,18.91,0,2.23.09,4.94.18,7.82.21,6.27.42,12.75-.22,16.08a14.67,14.67,0,0,1-1.2,3.64c-.69,1.41-1.77,3.2-3,5.27-2.56,4.22-6.06,10-6,12.87a2,2,0,0,1-2,2Z"
                  transform="translate(-31.94 -2.65)"
                />
                <g>
                  <path
                    fill="rgb(115,154,208)"
                    d="M379.42,59h-.11a9.62,9.62,0,0,1-5.61-2.55,2,2,0,0,1,2.69-3A5.8,5.8,0,0,0,379.55,55a2,2,0,0,1-.13,4Z"
                    transform="translate(-31.94 -2.65)"
                  />
                  <path
                    fill="rgb(115,154,208)"
                    d="M399.68,55a2,2,0,0,1-1.3-3.52c1.62-1.37,2.41-3.48,2.34-6.27-.1-4.4-2.66-6.5-5.13-8.53-1.54-1.27-3.33-2.75-3.87-4.77a1.9,1.9,0,0,1-1.86.23c-1.74-.65-1.46-2.22-1-4.83A58.54,58.54,0,0,0,390,15.44c-1.82,3.67-5.09,6.79-8.52,10.06-4.57,4.36-9.29,8.86-10.07,14.63a2,2,0,0,1-4-.53c1-7.16,6.44-12.39,11.27-17,3.81-3.64,7.41-7.07,8.43-10.89a7.55,7.55,0,0,0,.24-2.19c-.08-4.22,0-6.62,2.2-6.86s2.87,2.43,3.77,6.88A42.36,42.36,0,0,1,393.56,23a2.14,2.14,0,0,1,2.37-.2c2.13,1.07,1.13,4-.18,7.15-.44,1.05-.11,1.56,2.38,3.61,2.72,2.24,6.45,5.3,6.59,11.53.12,5-2,7.9-3.75,9.41A2,2,0,0,1,399.68,55Z"
                    transform="translate(-31.94 -2.65)"
                  />
                </g>
                <path
                  fill="rgb(57,102,174)"
                  d="M390.68,111.63a2,2,0,0,1-1.65-.87,64.51,64.51,0,0,1-6.09-12.58C378,85.27,372.8,83.26,372.75,83.24a2,2,0,0,1-1.31-2.49A2,2,0,0,1,374,79.43c.71.21,7,2.51,12.71,17.32a62.67,62.67,0,0,0,5.65,11.74,2,2,0,0,1-1.64,3.14Z"
                  transform="translate(-31.94 -2.65)"
                />
              </g>
              <g>
                <path
                  fill="rgb(57,102,174)"
                  d="M96.93,83.44H96a4,4,0,0,0-3.4,1.9L66.49,127.68,40.35,85.34a4,4,0,0,0-3.41-1.9h-1a4,4,0,0,0-4,4v44.27a2.84,2.84,0,0,0,5.68,0v-40l24.52,39.71a4.9,4.9,0,0,0,4.19,2.33h.16a4.89,4.89,0,0,0,4.18-2.33L95.31,91.65V130.9a2.81,2.81,0,0,0,5.62,0V87.44A4,4,0,0,0,96.93,83.44Z"
                  transform="translate(-31.94 -2.65)"
                />
                <path
                  fill="rgb(57,102,174)"
                  d="M231.26,109.28h-27.1a3,3,0,0,0,0,6h25c0,3.07-.29,4.63-2.74,7.7-3.09,4-9.27,5.9-19.45,5.9-13.75,0-18.43-3.88-20.74-6.8-2.49-3.19-3.81-7.86-3.81-13.5s1.34-10.2,4-13.65c2.39-3.07,6.78-6.59,19.51-6.59,8.14,0,16,1.6,21.56,4.38a3,3,0,0,0,2.94-.13,2.93,2.93,0,0,0,1.4-2.52,3,3,0,0,0-1.6-2.65c-6.07-3.18-15.15-5.08-24.3-5.08-11.72,0-19.38,2.82-24.1,8.88-3.31,4.24-5,10.24-5,17.36s1.75,13.12,5,17.29c4.76,6.1,12.76,8.94,25.17,8.94,12.07,0,19.65-2.54,23.83-8,3.18-4.07,4.1-7,4.1-13v-.88A3.67,3.67,0,0,0,231.26,109.28Z"
                  transform="translate(-31.94 -2.65)"
                />
                <path
                  fill="rgb(57,102,174)"
                  d="M293.93,127.72h-41.1a.14.14,0,0,1-.14-.14V115.33h40.5a3,3,0,0,0,0-5.93h-40.5V89.57a.14.14,0,0,1,.14-.14h41.1a3,3,0,0,0,0-6H251.44A4.42,4.42,0,0,0,247,87.86v41.43a4.42,4.42,0,0,0,4.42,4.42h42.49a3,3,0,0,0,0-6Z"
                  transform="translate(-31.94 -2.65)"
                />
                <path
                  fill="rgb(57,102,174)"
                  d="M145.54,85.67a5,5,0,0,0-4.34-2.5h-.12a5,5,0,0,0-4.34,2.5l-25.37,43.87a2.78,2.78,0,0,0,2.4,4.17,2.79,2.79,0,0,0,2.41-1.39l24.88-43.07,14.53,25.68h-20.4a3,3,0,1,0,0,6h21.66a3.78,3.78,0,0,1,3.71,2.13l5.39,9.29a2.8,2.8,0,0,0,2.4,1.38h.17a2.78,2.78,0,0,0,2.4-4.17Z"
                  transform="translate(-31.94 -2.65)"
                />
              </g>
            </g>
          </svg>
        </a>
        <a
          href="https://www.glittercatbarista.com/"
          className="credits__logo"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="u-screenReader">Glitter Cat Barista</span>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 440 170">
            <g>
              <path
                fill="#3966AE"
                d="M407.5,53.1h6.9c0.9,0,1.9,0.1,2.9,0.2c1,0.1,1.8,0.4,2.5,0.8c0.7,0.4,1.3,1,1.7,1.7
              c0.4,0.8,0.5,1.8,0.3,3.1c-0.2,1.4-0.6,2.4-1.3,3.2c-0.7,0.8-1.5,1.3-2.4,1.7c-0.9,0.4-1.9,0.6-2.9,0.6c-1.1,0.1-2.1,0.1-3,0.1
              H406L407.5,53.1z M391.6,94h10.5l2.6-20.1h5.2l7.3,20.1h12.6l-9.3-21.2c3.5-0.7,6.2-2.2,8.3-4.7c2.1-2.5,3.3-5.5,3.8-9.1
              c0.4-2.9,0.2-5.4-0.5-7.3c-0.7-2-1.8-3.5-3.3-4.7c-1.5-1.2-3.3-2-5.4-2.5c-2.1-0.5-4.4-0.7-6.8-0.7h-18.4L391.6,94z M349.7,94
              h33.6l1.3-10.2h-23.1l1.3-10.2h20.7l1.3-10.2h-20.7l1.3-9.4h21.9l1.3-10.2h-32.4L349.7,94z M319.9,94h10.5l5.4-40.5h13.6l1.3-9.8
              h-37.7l-1.3,9.8h13.6L319.9,94z M281.3,94h10.5l5.4-40.5h13.6l1.3-9.8h-37.7l-1.3,9.8h13.6L281.3,94z M252,94h10.5l6.7-50.3h-10.5
              L252,94z M216.4,94h29.9l1.3-10.2h-19.4l5.3-40h-10.5L216.4,94z M210.8,64.1h-18.6l-1.4,10.2h8.5l-1.1,8.2c-1,0.6-2.4,1.1-4.2,1.7
              c-1.8,0.5-3.8,0.8-6.1,0.8c-2.2,0-4.2-0.4-5.9-1.2c-1.7-0.8-3.1-1.9-4.2-3.4c-1.1-1.4-1.9-3.1-2.3-5.1c-0.4-2-0.5-4.1-0.2-6.5
              c0.3-2.3,1-4.5,1.9-6.5c1-2,2.2-3.7,3.7-5.1c1.5-1.4,3.2-2.6,5.1-3.4c1.9-0.8,4-1.2,6.3-1.2c2.7,0,4.9,0.4,6.6,1.2
              c1.7,0.8,3.2,2,4.4,3.5l8.5-8.5c-2.1-2.4-4.7-4-7.8-5s-6.5-1.5-10.3-1.5c-3.7,0-7.2,0.6-10.6,1.8c-3.3,1.2-6.3,3-8.8,5.3
              c-2.6,2.3-4.7,5.1-6.5,8.3c-1.7,3.3-2.9,6.9-3.4,10.9c-0.5,4-0.4,7.7,0.5,10.9s2.3,6,4.2,8.3c2,2.3,4.5,4.1,7.4,5.3
              s6.3,1.8,10.1,1.8c3.5,0,7-0.4,10.4-1.1c3.5-0.8,6.9-2,10.2-3.7L210.8,64.1z"
              />
              <path
                fill="#3966AE"
                d="M268.8,157.4h11.4L286,116h14.7l1.4-10h-40.9l-1.4,10h14.7L268.8,157.4z M242,136.9h-12.6l8.5-16
              L242,136.9z M206.1,157.4h12.5l5.8-10.9h20.1l2.9,10.9h12.8L245.1,106h-9.4L206.1,157.4z M210.4,112.2c-0.9-1.4-2-2.6-3.2-3.6
              c-1.3-1-2.7-1.7-4.1-2.3c-1.5-0.6-3-1-4.5-1.3c-1.5-0.3-3-0.4-4.5-0.4c-4,0-7.9,0.6-11.5,1.9c-3.6,1.3-6.8,3.1-9.6,5.4
              c-2.8,2.4-5.1,5.2-7,8.5c-1.9,3.3-3.1,7.1-3.7,11.2c-0.6,4.1-0.4,7.8,0.5,11.2c0.9,3.3,2.5,6.2,4.6,8.5c2.1,2.4,4.8,4.2,8.1,5.4
              c3.2,1.3,6.9,1.9,10.9,1.9c3.5,0,7.1-0.7,10.7-2.2c3.5-1.5,6.6-3.7,9.3-6.8l-8.5-7.1c-1.4,1.7-3.1,3.1-5,4.1
              c-1.9,1-4.1,1.5-6.4,1.5c-2,0-3.9-0.4-5.6-1.2c-1.7-0.8-3.1-2-4.2-3.5c-1.1-1.5-1.9-3.2-2.3-5.2c-0.5-2-0.5-4.2-0.2-6.7
              c0.3-2.4,1-4.6,2.1-6.6c1-2,2.3-3.8,3.9-5.3c1.5-1.5,3.3-2.6,5.2-3.5c1.9-0.8,4-1.2,6.1-1.2c2.1,0,4,0.4,5.5,1.1
              c1.5,0.7,2.7,1.8,3.7,3.1L210.4,112.2z"
              />
              <g>
                <path
                  fill="#3966AE"
                  d="M99.9,115c1.7,10.4,3.5,20.6,5.1,30.8c1,6.3,3.6,10.4,10.6,11.3c3.6,0.4,7.7,1.7,9.2,6.9
                c-7.3,0-13.9,0.2-20.5-0.1c-1.5-0.1-3.8-1.4-4.2-2.6c-3.2-8.9-6.7-17.8-8.9-27C89.1,125.8,92.6,119.2,99.9,115 M93.6,161.9
                c-0.2,0.8-0.3,1.7-0.5,2.5c-7.5,0-15.1,0.1-22.6,0c-2.4,0-5.3,0.2-7.1-1c-14.4-9-22-20.1-18.8-37.4c0.9-5.2,2.2-10.4,3.5-15.6
                c3.3-13,5.7-26,3.8-39.4c-0.8-5.6-3.7-9.6-8.8-11.7c-5.2-2.2-10.4-1-14.2,2.9c-2.8,2.9-4.9,6.6-6.9,10.3
                c-2.6,4.8-4.4,10.2-7.3,14.7c-1.4,2.2-5.7,4.9-7.3,4.2c-4.2-1.8-2.6-6-1.2-9.4c0.6-1.3,1.5-3.2,2.7-3.5c5.6-1.6,6.6-6.7,9.1-10.7
                c2.2-3.5,4.5-7.2,7.5-10c12.5-11.7,28.8-7.2,32,9.4c2,10.3,1.4,21.1,1.5,31.7c0,5.6-1,11.1-1.6,16.7c8.1-11,16.1-21.9,22.4-33.6
                c9.3-17.3,8.9-35.7,3.5-54.3c-2.2-7.5-4-15.2-6-22.8C77.9,4.6,78.4,4.3,79,4c2.9,3.1,5.7,6.3,7.7,8.4c4.6-1.7,8.8-4.8,12.7-4.5
                c8.3,0.6,16.7,2.7,24.8,4.9c3.9,1.1,7.1,4,6,9c-1.1,4.9-2.5,9.7-8.7,10.6c-1.8,0.3-4.1,0.6-5.3,1.8c-2.3,2.4-6,5.8-5.5,8
                c0.6,2.9,4.4,6.1,7.4,7.2c2.8,1,7.1,0.2,9.7-1.4c3.9-2.5,6.9-6.3,10.1-9.7c3.3-3.6,6.1-7.8,9.7-11c5.6-4.8,10.2-3.7,15.3,3.9
                c-2.7,1.2-5.2,2.3-7.7,3.4c-2.5,1.1-5.6,1.7-7.2,3.6c-2.9,3.4-4.6,7.9-7.5,11.3c-4,4.9-8.6,9.3-13,13.8c-1.1,1.1-2.6,1.8-4.8,3.3
                h11.2c-4.2,11.3-10.3,20.9-18.7,28.8c-7.6,7.1-15.8,13.6-23.9,20.1c-6.7,5.4-10.2,12-7.9,20.4c2.1,7.6,1.7,14.6-2.1,22.2
                C85.6,159.4,89.6,160.6,93.6,161.9"
                />
              </g>
            </g>
          </svg>
        </a>
        <a
          href="https://gofundbean.org/"
          className="credits__logo"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440 170">
            <g>
              <polygon fill="#2A2A2A" points="13.4,93.8 13.4,93.8 13.4,93.8 				" />
              <path fill="#FFFFFF" d="M13.4,95.8c-1.1,0-2-0.9-2-2s0.9-2,2-2h0c1.1,0,2,0.9,2,2S14.5,95.8,13.4,95.8z" />
            </g>
            <g>
              <path
                fill="#3966AE"
                d="M199.8,54.8c-1.3-6.1-3.4-12-6.4-17.7c-1.6-3.1-4.6-6.6-8.7-6c-3.5,0.5-5.2,3.6-6.7,6.2
              c-5.9,9.8-18.8,15.8-31.9,19.4c-13,3.6-26.9,5.3-39.6,9.6c-15.2,5.1-28,13.6-41.4,21.2c-1.9,1.1-3.9,2.2-6,3
              c-1.9,0.7-3.9,1.2-5.9,1.6C39.7,95,27,96,13.4,93.8c8.7,19.7,34.3,34,60.8,33.9c6.8,0,13.6-0.9,20.3-1.8l14.3-1.8
              c1.8-3.4,3.7-6.7,5.7-10c-2.7-2.4-5.4-4.7-8-7.1l-5.2-4.6l0.7-1.1c4.5-7.7,10.1-14.7,16.6-20.6l9.2-8.5l-6.9,10.5
              c-3.2,5-5.9,10.3-8,15.9c-0.2,0.5-0.7,1.9-0.3,2.5c0.2,0.4,0.9,0.6,1.5,0.9c4.9,1.8,9.9,3.6,14.8,5.4l2.1,0.8l-1.4,1.7
              c-3.4,4.1-6.6,8.4-9.6,12.8c3.8-0.5,7.7-1.1,11.4-1.9c6.6-1.3,13-3.3,19.1-5.7c11.2-4.5,21.8-10,29.3-17.9
              c7.6-7.9,12.7-17.1,17.4-26.2c1.3-2.6,2.6-5.2,3.1-7.9C200.8,60.2,200.4,57.5,199.8,54.8z"
              />
              <path
                fill="#739AD0"
                d="M74,129.7c-27.3,0-53.5-14.7-62.5-35.1c-0.3-0.7-0.2-1.5,0.2-2s1.2-0.9,1.9-0.7
              c12.3,2,24.3,1.5,39.1-1.7c1.7-0.4,3.8-0.8,5.7-1.6c2-0.7,3.8-1.7,5.8-2.9c2.8-1.6,5.5-3.2,8.2-4.8
              c10.5-6.1,21.3-12.4,33.5-16.6c7.5-2.5,15.4-4.2,23-5.8c5.5-1.2,11.2-2.4,16.6-3.9c10.7-2.9,24.6-8.5,30.7-18.5l0.3-0.5
              c1.5-2.5,3.6-6,7.8-6.6c4.2-0.6,8.2,2.1,10.8,7c3,5.8,5.3,12,6.6,18.2c0.6,2.6,1.1,5.7,0.6,8.9c-0.5,3-1.8,5.6-3.3,8.5
              c-4.6,9.1-10,18.6-17.8,26.7c-6.7,7-16,12.7-30,18.4c-6.2,2.5-12.8,4.5-19.5,5.8c-4.1,0.8-8.1,1.4-11.6,1.9
              c-0.8,0.1-1.6-0.3-2-0.9c-0.4-0.7-0.4-1.5,0-2.2c2.9-4.3,6.1-8.5,9.3-12.4l-14.2-5.2c-1-0.4-2-0.8-2.6-1.7
              c-0.8-1.3-0.3-2.9,0.2-4.3c1.7-4.6,3.9-9.1,6.4-13.3c-5.2,5.2-9.7,11-13.5,17.4l4.9,4.3c2.4,2.1,4.7,4.2,7.1,6.3
              c0.7,0.6,0.9,1.7,0.4,2.5c-1.9,3.1-3.8,6.4-5.6,9.9c-0.3,0.6-0.9,1-1.5,1l-14.3,1.8c-6.7,0.9-13.5,1.8-20.6,1.8
              C74.1,129.7,74.1,129.7,74,129.7z M16.8,96.3c9.6,17.2,32.9,29.4,57.2,29.4c0.1,0,0.1,0,0.2,0c6.8,0,13.6-0.9,20.1-1.7l13.3-1.7
              c1.4-2.7,2.9-5.3,4.4-7.7c-2-1.7-3.9-3.4-5.9-5.2l-6.1-5.4c-0.7-0.6-0.9-1.7-0.4-2.5l0.7-1.1c4.6-7.9,10.4-15,17-21.1l9.2-8.5
              c0.7-0.7,1.9-0.7,2.6-0.1c0.8,0.7,0.9,1.8,0.4,2.6l-6.9,10.5c-3.2,4.8-5.8,10.1-7.8,15.5c-0.1,0.3-0.2,0.6-0.2,0.7
              c0.1,0,0.2,0.1,0.3,0.1l16.8,6.2c0.6,0.2,1.1,0.7,1.2,1.4c0.2,0.6,0,1.3-0.4,1.8l-1.4,1.7c-2.4,2.8-4.7,5.8-6.9,8.9
              c2.2-0.3,4.5-0.7,6.8-1.2c6.5-1.3,12.8-3.2,18.8-5.6c13.5-5.5,22.3-10.9,28.7-17.5c7-7.3,11.9-15.6,17.1-25.8
              c1.3-2.6,2.5-5,2.9-7.4c0.4-2.5,0-5.1-0.5-7.4c0,0,0,0,0,0c-1.2-5.9-3.3-11.7-6.2-17.2c-0.7-1.3-3.1-5.4-6.7-4.9
              c-2.2,0.3-3.4,2-4.9,4.7l-0.3,0.6c-5.4,8.9-16.5,15.8-33.1,20.3c-5.6,1.5-11.3,2.7-16.9,3.9c-7.5,1.6-15.4,3.2-22.6,5.7
              c-11.8,4-22.5,10.2-32.7,16.2c-2.7,1.6-5.5,3.2-8.3,4.8c-2,1.2-4.1,2.3-6.3,3.1c-2.1,0.8-4.4,1.3-6.2,1.7
              C39.9,97,28.3,97.7,16.8,96.3z M114.4,100.5L114.4,100.5L114.4,100.5z"
              />
            </g>
            <path
              fill="#3966AE"
              d="M11.7,93.5C1.8,86,15.3,56.8,39.1,38.7c14.7-11.2,30.8-16.2,42.7-19.9c13-4.1,27.7-8.7,46.1-8.3
            c31.8,0.6,63.7,15.9,61.9,24.3c-1.3,6-18.3,3.5-55.7,16.3c-10.6,3.6-19.9,7.6-38.7,15.5c-19,8-28.4,12-34.7,15.5
            c-8.9,5-12.3,7.8-20.5,10.2C36.4,93.3,18.5,98.7,11.7,93.5"
            />
            <path
              fill="#739AD0"
              d="M20.7,97.7c-4.1,0-7.8-0.7-10.3-2.6l0,0c-2.7-2-4.1-5.3-4.1-9.6c0-13.7,13.8-35,31.5-48.4
            c15-11.4,31.3-16.5,43.3-20.3c13.1-4.1,28-8.8,46.7-8.4c12.5,0.2,26.1,2.8,38.3,7.1c11.3,4,19.9,8.9,23.5,13.6
            c1.7,2.1,2.3,4.2,1.9,6.1c-0.9,4.3-6,5.3-16,7.2c-9,1.7-22.5,4.3-41,10.6c-10.6,3.6-20.5,7.8-38.6,15.4l-0.2,0.1
            c-18.8,7.9-28.2,11.9-34.3,15.3c-2.2,1.3-4.2,2.4-5.9,3.4c-5.1,3-8.7,5.1-15,7C34.4,96,27,97.7,20.7,97.7z M125.8,12.4
            c-17.2,0-31.1,4.4-43.5,8.3c-11.7,3.7-27.7,8.7-42.1,19.6C25.9,51.3,10.4,71.7,10.4,85.5c0,3,0.8,5.1,2.5,6.4l0,0
            c3.8,2.9,13.5,2.3,26.6-1.6c5.9-1.7,9.2-3.7,14.2-6.6c1.7-1,3.7-2.1,5.9-3.4c6.4-3.5,15.8-7.5,34.7-15.5l0.2-0.1
            c18.1-7.6,28.1-11.9,38.8-15.5c18.8-6.4,32.5-9,41.6-10.8c7.2-1.4,12.5-2.4,12.8-4.1c0.1-0.7-0.3-1.6-1.1-2.8
            c-3.1-3.9-11.5-8.6-21.7-12.3c-11.8-4.2-25-6.6-37.1-6.8C127.1,12.4,126.5,12.4,125.8,12.4z"
            />
            <path
              fill="#3966AE"
              d="M274.9,105.2h-33.1v18.6h11.3v15.4c-1,0.4-4,0.8-7,0.8c-13.9,0-24.1-9.7-24.1-26.4
            c0-18.2,11.6-25.9,25.5-25.9c9.7,0,15.3,1.6,20.1,3.7l4.5-19.1c-4.4-2.3-13.1-4.4-24.5-4.4c-27.5,0-49.3,15.8-49.5,46.9
            c0,12.9,4.1,24.4,12,32.4c7.7,7.7,19,12.1,35.6,12.1c11,0,22.8-2.9,29.1-5.2L274.9,105.2L274.9,105.2z"
            />
            <path
              fill="#739AD0"
              d="M245.8,161.2c-16.5,0-28.6-4.1-37.1-12.7c-8.1-8.2-12.6-20.2-12.6-33.8c0.1-29.7,20.3-48.9,51.5-48.9
            c10.3,0,19.8,1.7,25.4,4.6l1.4,0.7l-5.4,23l-2.2-1c-3.9-1.8-9.3-3.5-19.3-3.5c-7.1,0-23.5,2.3-23.5,23.9
            c0,14.8,8.7,24.4,22.1,24.4c2,0,3.9-0.2,5-0.4v-11.8h-11.3v-22.6h37.1v52.3l-1.3,0.5C268.8,158.4,256.9,161.2,245.8,161.2z
              M247.5,69.8c-34.9,0-47.4,23.2-47.5,44.9c0,12.5,4.1,23.5,11.5,31c7.7,7.7,18.9,11.5,34.2,11.5c9.3,0,20.1-2.2,27.1-4.6v-45.5
            h-29.1v14.6h11.3v18.8l-1.3,0.5c-1.3,0.5-4.7,0.9-7.8,0.9c-15.6,0-26.1-11.4-26.1-28.4c0-17.5,10.3-27.9,27.5-27.9
            c9,0,14.6,1.4,18.7,3l3.6-15.3C265.4,71.5,257.7,69.8,247.5,69.8z"
            />
            <path fill="#3966AE" d="M289.1,158.3h22.7v-34h29.7v-19.4h-29.7V88.3h31.6V68.8h-54.3V158.3z" />
            <path
              fill="#739AD0"
              d="M313.8,160.3h-26.7V66.8h58.3v23.5h-31.6v12.6h29.7v23.4h-29.7V160.3z M291.1,156.3h18.7v-34h29.7v-15.4
            h-29.7V86.3h31.6V70.8h-50.3V156.3z"
            />
            <path
              fill="#3966AE"
              d="M380,120.2h5.4c8.5,0,15.1,2.9,15.1,10.5c0,7.8-6.4,11-13.9,11c-2.9,0-4.9,0-6.5-0.3V120.2z M380,85.8
            c1.5-0.3,3.6-0.5,7.4-0.5c7.7,0,11.4,3.3,11.4,8.6c0,5.7-4.6,9.3-14.3,9.3H380V85.8z M357.5,157.8c4.6,0.8,12,1.5,22.3,1.5
            c18.1,0,28.6-2.9,35-8c5.7-4.5,9.4-11.2,9.4-19.5c0-10.6-6.1-18.7-16.6-21.8v-0.3c9.9-4,13.8-11.3,13.8-18.9
            c0-8.6-4.9-15.1-11.4-18.3c-6.7-3.6-13.8-4.4-25.9-4.4c-10.4,0-21.6,0.9-26.7,1.9L357.5,157.8L357.5,157.8z"
            />
            <path
              fill="#739AD0"
              d="M379.8,161.2c-8.9,0-16.9-0.5-22.5-1.5h-1.8V68.3l1.6-0.3c5.1-0.9,16.3-1.9,27.1-1.9
            c11.8,0,19.6,0.7,26.8,4.6c7.8,3.8,12.5,11.3,12.5,20.1c0,8.1-3.9,14.6-11.1,18.8c8.7,4.1,13.8,12.1,13.8,22.1
            c0,8.4-3.6,15.9-10.2,21.1C408.8,158.6,397.3,161.2,379.8,161.2z M359.5,156.1c3.7,0.5,10.2,1.2,20.3,1.2
            c16.5,0,27.3-2.4,33.8-7.5c3.9-3.1,8.6-8.8,8.6-18c0-9.8-5.5-17-15.1-19.9l-1.4-0.4v-3.1l1.3-0.5c10.4-4.2,12.6-11.6,12.6-17
            c0-7.2-3.9-13.4-10.3-16.5c-6.5-3.5-13.8-4.2-25-4.2c-9.5,0-19.3,0.8-24.7,1.6V156.1z M386.5,143.7c-2.9,0-5.1,0-6.8-0.3
            l-1.7-0.3v-25h7.4c10.8,0,17.1,4.6,17.1,12.5C402.4,138.7,396.3,143.7,386.5,143.7z M382,139.6c1.2,0.1,2.7,0.1,4.5,0.1
            c10.4,0,11.9-5.7,11.9-9c0-3.9-2.3-8.5-13.1-8.5H382V139.6z M384.5,105.2H378V84.1l1.7-0.3c1.5-0.3,3.7-0.6,7.8-0.6
            c8.4,0,13.4,4,13.4,10.6C400.8,97.3,399.2,105.2,384.5,105.2z M382,101.2h2.5c5.6,0,12.3-1.3,12.3-7.3c0-4.4-3.2-6.6-9.4-6.6
            c-2.4,0-4.1,0.1-5.4,0.3V101.2z"
            />
            <path
              fill="#739AD0"
              d="M58.3,79.4c5.8-2.9,10.9-6.7,16.4-10c11.7-7.2,24.8-12.4,37.9-17.6c20.9-8.2,42-16.5,64.5-20.4
            c1.9-0.3,4-0.6,5.7,0.3c3.1,1.7,1.7,5.8-0.4,8.4c-4.9,6-12.1,10.7-20.2,13.1c-4.2,1.3-8.7,2-12.9,3.4c-4.7,1.6-8.9,4.2-13.6,5.9
            c-5.3,1.9-11,2.8-16.5,4.1c-19.3,4.8-35.6,15.6-52.6,24.7c-6.8,3.6-14,7.1-21.9,8.1c-5.9,0.8-27.6-2.4-17-8.5
            C36.9,85.5,48.7,84.1,58.3,79.4"
            />
          </svg>
        </a>
      </div>
      <div className="f fdr jca xsmall footer__links">
        <Link to="/pages/terms-and-conditions">
          Terms & Conditions
        </Link>
        <span>
          &nbsp; | &nbsp;
        </span>
        <Link to="/pages/sponsorships">
          Sponsorship Info
        </Link>
      </div>
    </div>
  </nav>
);

export default Menu;
