import React, { useState } from 'react';

import routes from '../routes';

import { Menu, X } from 'react-feather';

import Logo from './logo';
import NavLinks from './navLinks';


const MobileNav = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <nav className="mobileNav">
      <div className="header__wrapper x f fdr jcb aic">
        <Logo />
        <div
          navbarOpen={navbarOpen}
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          {navbarOpen ? <X /> : <Menu /> }
        </div>
      </div>
      {navbarOpen ? (
        <NavLinks />
      ) : (
        <NavLinks closed />
      )}
    </nav>
  );
};

export default MobileNav;
