import React from "react";
import Helmet from "react-helmet";

import socialImg from "../assets/images/come-together-og-noBg.png";

const Header = ({ siteTitle }) => (
  <Helmet>
    <html lang="en" />
    <title>{siteTitle}</title>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta property="og:image" content={socialImg} />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={siteTitle} />
    <meta
      property="og:description"
      content="Your favorite cafes and coffee roasters need our help. As we keep our distance to save lives, let’s Come Together to save livelihoods."
    />
    <meta property="og:url" content="https://cometogether.coffee" />
  </Helmet>
);

export default Header;
