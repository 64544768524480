import React from "react";

const Footer = () => (
  <footer className="footer">
    <div className="site__credits f rel x jcb">
      <span>With love –</span>
      <a
        href="https://fellowproducts.com"
        className="credits__logo fellowLogo"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="u-screenReader">Fellow Products</span>
        <svg
          id="Fellow Logo"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 390.36 74.16"
        >
          <g>
            <path
              fill="rgb(57,102,174)"
              d="M311.5,140.83c-.57,0-10.56,3.95-14.38,5.48-3.84-1.53-13.82-5.48-14.4-5.48a1.25,1.25,0,0,0-1.26,1.25v12.16a1.25,1.25,0,0,0,1.26,1.26c.58,0,10.56-4,14.4-5.48,3.82,1.52,13.81,5.48,14.38,5.48a1.25,1.25,0,0,0,1.26-1.26V142.08a1.24,1.24,0,0,0-1.26-1.25"
              transform="translate(-26.45 -81.34)"
            />
            <path
              fill="rgb(57,102,174)"
              d="M59.47,83.12H28.29A1.85,1.85,0,0,0,26.45,85v46.45a1.84,1.84,0,0,0,1.84,1.84h3.09a1.83,1.83,0,0,0,1.83-1.84v-20.9h16a1.84,1.84,0,0,0,1.84-1.84v-2.76a1.84,1.84,0,0,0-1.84-1.84h-16V89.55H59.47a1.84,1.84,0,0,0,1.85-1.83V85a1.85,1.85,0,0,0-1.85-1.84"
              transform="translate(-26.45 -81.34)"
            />
            <path
              fill="rgb(57,102,174)"
              d="M125.21,89.55A1.83,1.83,0,0,0,127,87.72V85a1.83,1.83,0,0,0-1.83-1.84H93.29A1.83,1.83,0,0,0,91.46,85v46.45a1.84,1.84,0,0,0,1.83,1.84h31.92a1.84,1.84,0,0,0,1.83-1.84v-2.84a1.83,1.83,0,0,0-1.83-1.83H98.14V110.5H114a1.84,1.84,0,0,0,1.84-1.83v-2.76a1.84,1.84,0,0,0-1.84-1.84H98.14V89.55Z"
              transform="translate(-26.45 -81.34)"
            />
            <path
              fill="rgb(57,102,174)"
              d="M189.17,126.74H165V85a1.85,1.85,0,0,0-.54-1.3,1.81,1.81,0,0,0-1.3-.54h-3A1.85,1.85,0,0,0,158.34,85v46.45a1.83,1.83,0,0,0,1.84,1.83h29a1.83,1.83,0,0,0,1.84-1.83v-2.84a1.84,1.84,0,0,0-1.84-1.83"
              transform="translate(-26.45 -81.34)"
            />
            <path
              fill="rgb(57,102,174)"
              d="M248.93,126.74H224.77V85a1.84,1.84,0,0,0-1.83-1.84h-3A1.84,1.84,0,0,0,218.1,85v46.45a1.82,1.82,0,0,0,1.83,1.83h29a1.82,1.82,0,0,0,1.83-1.83v-2.84a1.83,1.83,0,0,0-1.83-1.83"
              transform="translate(-26.45 -81.34)"
            />
            <path
              fill="rgb(57,102,174)"
              d="M297.16,81.34A26.88,26.88,0,1,0,324,108.26a26.95,26.95,0,0,0-26.84-26.92m0,47.3a20.42,20.42,0,1,1,20.37-20.38,20.44,20.44,0,0,1-20.37,20.38"
              transform="translate(-26.45 -81.34)"
            />
            <path
              fill="rgb(57,102,174)"
              d="M416,84.66l-2.17-1.49a1.83,1.83,0,0,0-1.54-.25,1.8,1.8,0,0,0-1.18,1l-15.33,35.41L380.44,84.05a1.82,1.82,0,0,0-.64-.78l-.11-.07a2,2,0,0,0-1.93-.09,1.82,1.82,0,0,0-.86.91l-15.29,35.35L346.21,84a1.85,1.85,0,0,0-1-1,1.79,1.79,0,0,0-1.45.07l-2.24,1.09a1.83,1.83,0,0,0-.88,2.38L359.86,131a1.81,1.81,0,0,0,1.67,1.11h0a1.84,1.84,0,0,0,1.68-1.1L378.7,95.55,394,131a1.85,1.85,0,0,0,1.68,1.11h0a1.87,1.87,0,0,0,1.69-1.1l19.25-44.09a1.84,1.84,0,0,0-.65-2.25"
              transform="translate(-26.45 -81.34)"
            />
          </g>
        </svg>
      </a>
      <a
        href="https://wearemage.com"
        className="credits__logo mageLogo"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="u-screenReader">Mage</span>
        <svg
          version="1.1"
          id="Mage Logo"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 376.99 164"
        >
          <g>
            <g>
              <path
                fill="rgb(57,102,174)"
                d="M354.66,166.65a2.09,2.09,0,0,1-.43,0,2,2,0,0,1-1.52-2.38c0-.12,1.43-7.16-2.75-16.11-4.44-9.52-20.55-33.16-20.72-33.4a1.78,1.78,0,0,1-.24-.5l-7.17-21.58a13.33,13.33,0,0,1-.55-2.47,2.28,2.28,0,0,1,0-.26l.07-17.56a9.71,9.71,0,0,1,.39-2.7l5.14-18.35a3.93,3.93,0,0,1,2.05-2.49,3.79,3.79,0,0,1,3.16-.07c4.4,1.87,6.4,6.55,1.83,23.36a3.51,3.51,0,0,0-.07,1.37L335.07,87a8.87,8.87,0,0,0,.63,1.72c2.54,5.2,5,10.53,5.52,14.26a2,2,0,0,1-.53,1.66,15.2,15.2,0,0,0-1.86,2.44,2,2,0,1,1-3.51-1.92,15.78,15.78,0,0,1,1.77-2.49c-.83-3.7-3.6-9.36-5-12.2a12.7,12.7,0,0,1-1-2.67,2.74,2.74,0,0,1,0-.28l-1.25-13.6a7.46,7.46,0,0,1,.19-2.82c4.36-16,1.78-18,.64-18.55l-5.1,18.22a5.31,5.31,0,0,0-.25,1.62l-.07,17.45a9.65,9.65,0,0,0,.37,1.61l7.07,21.3c1.8,2.64,16.49,24.32,20.88,33.73,4.8,10.28,3.11,18.32,3,18.65A2,2,0,0,1,354.66,166.65Z"
                transform="translate(-31.94 -2.65)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M350.48,90.57a2,2,0,0,1-1.91-1.4l-2.21-7a2,2,0,0,1,.21-1.65A59.81,59.81,0,0,0,353,66.63,2,2,0,0,1,354,65.35a57.35,57.35,0,0,0,12.26-8.44c4.79-4.43,3.43-9,3.37-9.22a1.9,1.9,0,0,0-.21-.43,5.33,5.33,0,0,0-1.12,1.08c-2.74,3.36-10.93,8.16-18.39,12.4L348,61.85c-2.13,1.23-5.8,10.86-7.37,15C340,78.5,339.41,80,339,80.89a2,2,0,1,1-3.66-1.62c.37-.84.91-2.25,1.53-3.88,3.15-8.24,6-15.2,9.11-17l2-1.12c4.8-2.73,14.77-8.4,17.27-11.46,1.66-2,3.35-2.89,5-2.56,2.31.45,3.14,3,3.23,3.24s2.27,7.12-4.47,13.36a60.8,60.8,0,0,1-12.43,8.69,65.6,65.6,0,0,1-6.11,13.29L352.38,88a2,2,0,0,1-1.3,2.51A2.13,2.13,0,0,1,350.48,90.57Z"
                transform="translate(-31.94 -2.65)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M342,60.72a2,2,0,0,1-2-1.62c-.5-2.53-3.44-26.14-1.22-30.89,4.9-10.51,6.13-15.42,6.3-17.69.07-.93.29-3.79,2.44-4.92a4,4,0,0,1,4,.29,4.74,4.74,0,0,1,.94.73A16,16,0,0,1,356.65,21c-1.41,6.47-2.94,11.4-3.47,13.06a88.1,88.1,0,0,1,2.62,16.57,2,2,0,0,1-4,.24,84,84,0,0,0-2.64-16.31,2,2,0,0,1,0-1.2,133,133,0,0,0,3.55-13.21,11.84,11.84,0,0,0-3-10.69l-.12-.1-.14-.07a5.23,5.23,0,0,0-.3,1.56c-.28,3.77-2.53,10.19-6.67,19.07C341.18,32.69,343,53,344,58.33a2,2,0,0,1-1.58,2.35A2.45,2.45,0,0,1,342,60.72Z"
                transform="translate(-31.94 -2.65)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M361.06,82.88a2.28,2.28,0,0,1-.44,0,2,2,0,0,1-1.51-2.4c.38-1.7-1-6.52-2.06-9.4a2,2,0,1,1,3.74-1.41c.51,1.34,3,8.23,2.22,11.7A2,2,0,0,1,361.06,82.88Z"
                transform="translate(-31.94 -2.65)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M355.88,91.7a2,2,0,0,1-1.62-3.16c2.18-3.07,8.94-6.77,13.91-7.62a2,2,0,0,1,.67,3.94c-4.25.73-9.87,4-11.33,6A2,2,0,0,1,355.88,91.7Z"
                transform="translate(-31.94 -2.65)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M363.54,46.6a2,2,0,0,1-2-1.56c-.11-.52-.4-1.45-.71-2.43-1.36-4.33-2.35-7.81-1.68-10,.49-1.65,1.38-5.3,1.87-7.32a16.21,16.21,0,0,0,.45-4.16c-.07-3-.79-4.66-2.15-5-.27-.07-.31-.08-.45,0s-1.26,1-2.18,4.47a2,2,0,0,1-3.87-1c1-3.64,2.29-5.9,4.06-6.92a4.42,4.42,0,0,1,3.47-.4c2.27.62,5,2.64,5.12,8.8a19.7,19.7,0,0,1-.56,5.19c-.5,2.07-1.41,5.8-1.93,7.54-.37,1.22,1.05,5.75,1.66,7.68.35,1.11.65,2.07.81,2.75A2,2,0,0,1,364,46.55,2.2,2.2,0,0,1,363.54,46.6Z"
                transform="translate(-31.94 -2.65)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M376,130.17a2,2,0,0,1-2-2c0-14.1-21.92-32.19-22.15-32.37a2,2,0,1,1,2.54-3.1c1,.79,23.61,19.49,23.61,35.47A2,2,0,0,1,376,130.17Z"
                transform="translate(-31.94 -2.65)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M369.93,78.78a2,2,0,0,1-2-1.94c-.08-2.72-1.94-8.8-2.65-10.92a2,2,0,0,1,3.79-1.28c.28.84,2.75,8.28,2.85,12.09a2,2,0,0,1-1.94,2Z"
                transform="translate(-31.94 -2.65)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M390.68,111.63a2,2,0,0,1-1.65-.87,64.51,64.51,0,0,1-6.09-12.58C378,85.27,372.8,83.26,372.75,83.24a2,2,0,0,1-1.31-2.49A2,2,0,0,1,374,79.43c.71.21,7,2.51,12.71,17.32a62.67,62.67,0,0,0,5.65,11.74,2,2,0,0,1-1.64,3.14Z"
                transform="translate(-31.94 -2.65)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M396.24,162.6a2,2,0,0,1-2-2c0-4,3.49-9.84,6.61-15,1.16-1.9,2.25-3.7,2.85-4.95a10.35,10.35,0,0,0,.87-2.64c.55-2.89.34-9.43.16-15.2-.1-2.91-.19-5.66-.19-7.95,0-8.1-4.62-17.26-4.67-17.35a2.55,2.55,0,0,1-.18-.53,161.61,161.61,0,0,0-7.15-24.48,6.26,6.26,0,0,1-.45-2.23c0-.14,0-.29,0-.43s0-.14,0-.21l.21-18.13a2.3,2.3,0,0,0,0-.54,3.37,3.37,0,0,0-1.23.24,1.8,1.8,0,0,1-.33.1h0s-3.4,1.07-5.53,8.29c-1.55,5.27-2.07,12.48.26,16.69,1.91,3.46,2.31,16.7,2.27,17.29a49.49,49.49,0,0,1-.51,5.21,2,2,0,1,1-3.93-.71h0s.24-1.43.45-4.75c.07-1.5-.63-13-1.78-15.1-3.09-5.58-2.33-13.87-.6-19.76,2.6-8.83,7.17-10.68,8.3-11,.74-.29,3.11-1.05,4.95.21a4.26,4.26,0,0,1,1.71,3.92l-.21,18c0,.15,0,.29,0,.44a3.27,3.27,0,0,0,.12.91,164.92,164.92,0,0,1,7.35,25c.82,1.65,5,10.54,5,18.91,0,2.23.09,4.94.18,7.82.21,6.27.42,12.75-.22,16.08a14.67,14.67,0,0,1-1.2,3.64c-.69,1.41-1.77,3.2-3,5.27-2.56,4.22-6.06,10-6,12.87a2,2,0,0,1-2,2Z"
                transform="translate(-31.94 -2.65)"
              />
              <g>
                <path
                  fill="rgb(115,154,208)"
                  d="M379.42,59h-.11a9.62,9.62,0,0,1-5.61-2.55,2,2,0,0,1,2.69-3A5.8,5.8,0,0,0,379.55,55a2,2,0,0,1-.13,4Z"
                  transform="translate(-31.94 -2.65)"
                />
                <path
                  fill="rgb(115,154,208)"
                  d="M399.68,55a2,2,0,0,1-1.3-3.52c1.62-1.37,2.41-3.48,2.34-6.27-.1-4.4-2.66-6.5-5.13-8.53-1.54-1.27-3.33-2.75-3.87-4.77a1.9,1.9,0,0,1-1.86.23c-1.74-.65-1.46-2.22-1-4.83A58.54,58.54,0,0,0,390,15.44c-1.82,3.67-5.09,6.79-8.52,10.06-4.57,4.36-9.29,8.86-10.07,14.63a2,2,0,0,1-4-.53c1-7.16,6.44-12.39,11.27-17,3.81-3.64,7.41-7.07,8.43-10.89a7.55,7.55,0,0,0,.24-2.19c-.08-4.22,0-6.62,2.2-6.86s2.87,2.43,3.77,6.88A42.36,42.36,0,0,1,393.56,23a2.14,2.14,0,0,1,2.37-.2c2.13,1.07,1.13,4-.18,7.15-.44,1.05-.11,1.56,2.38,3.61,2.72,2.24,6.45,5.3,6.59,11.53.12,5-2,7.9-3.75,9.41A2,2,0,0,1,399.68,55Z"
                  transform="translate(-31.94 -2.65)"
                />
              </g>
              <path
                fill="rgb(57,102,174)"
                d="M390.68,111.63a2,2,0,0,1-1.65-.87,64.51,64.51,0,0,1-6.09-12.58C378,85.27,372.8,83.26,372.75,83.24a2,2,0,0,1-1.31-2.49A2,2,0,0,1,374,79.43c.71.21,7,2.51,12.71,17.32a62.67,62.67,0,0,0,5.65,11.74,2,2,0,0,1-1.64,3.14Z"
                transform="translate(-31.94 -2.65)"
              />
            </g>
            <g>
              <path
                fill="rgb(57,102,174)"
                d="M96.93,83.44H96a4,4,0,0,0-3.4,1.9L66.49,127.68,40.35,85.34a4,4,0,0,0-3.41-1.9h-1a4,4,0,0,0-4,4v44.27a2.84,2.84,0,0,0,5.68,0v-40l24.52,39.71a4.9,4.9,0,0,0,4.19,2.33h.16a4.89,4.89,0,0,0,4.18-2.33L95.31,91.65V130.9a2.81,2.81,0,0,0,5.62,0V87.44A4,4,0,0,0,96.93,83.44Z"
                transform="translate(-31.94 -2.65)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M231.26,109.28h-27.1a3,3,0,0,0,0,6h25c0,3.07-.29,4.63-2.74,7.7-3.09,4-9.27,5.9-19.45,5.9-13.75,0-18.43-3.88-20.74-6.8-2.49-3.19-3.81-7.86-3.81-13.5s1.34-10.2,4-13.65c2.39-3.07,6.78-6.59,19.51-6.59,8.14,0,16,1.6,21.56,4.38a3,3,0,0,0,2.94-.13,2.93,2.93,0,0,0,1.4-2.52,3,3,0,0,0-1.6-2.65c-6.07-3.18-15.15-5.08-24.3-5.08-11.72,0-19.38,2.82-24.1,8.88-3.31,4.24-5,10.24-5,17.36s1.75,13.12,5,17.29c4.76,6.1,12.76,8.94,25.17,8.94,12.07,0,19.65-2.54,23.83-8,3.18-4.07,4.1-7,4.1-13v-.88A3.67,3.67,0,0,0,231.26,109.28Z"
                transform="translate(-31.94 -2.65)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M293.93,127.72h-41.1a.14.14,0,0,1-.14-.14V115.33h40.5a3,3,0,0,0,0-5.93h-40.5V89.57a.14.14,0,0,1,.14-.14h41.1a3,3,0,0,0,0-6H251.44A4.42,4.42,0,0,0,247,87.86v41.43a4.42,4.42,0,0,0,4.42,4.42h42.49a3,3,0,0,0,0-6Z"
                transform="translate(-31.94 -2.65)"
              />
              <path
                fill="rgb(57,102,174)"
                d="M145.54,85.67a5,5,0,0,0-4.34-2.5h-.12a5,5,0,0,0-4.34,2.5l-25.37,43.87a2.78,2.78,0,0,0,2.4,4.17,2.79,2.79,0,0,0,2.41-1.39l24.88-43.07,14.53,25.68h-20.4a3,3,0,1,0,0,6h21.66a3.78,3.78,0,0,1,3.71,2.13l5.39,9.29a2.8,2.8,0,0,0,2.4,1.38h.17a2.78,2.78,0,0,0,2.4-4.17Z"
                transform="translate(-31.94 -2.65)"
              />
            </g>
          </g>
        </svg>
      </a>
    </div>
  </footer>
);

export default Footer;
