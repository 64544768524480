/* eslint-disable react/no-unknown-property */
/* eslint-disable import/prefer-default-export */
import React from 'react';

export const IconTakeOut = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    width="24px"
    viewBox="0 0 24 24"
  >
    <defs>
      <style dangerouslySetInnerHTML={{
        __html: `
          .lj-round {
            stroke-linejoin: round;
            stroke-miterlimit: 10;
          }
        `,
      }}
      />
    </defs>
    <g>
      <path
        fill="#FFFFFF"
        stroke="#1D64CC"
        strokeWidth="1.5"
        className="lj-round"
        d="M17.9,8.5l3.7-5.2
        l1.4,5.8c0,0.2,0.1,0.4,0,0.6l-2.2,12.6c-0.1,0.5-0.6,0.9-1.1,0.8l-5-0.9L17.9,8.5z"
      />
      <path
        fill="#FFFFFF"
        stroke="#1D64CC"
        strokeWidth="1.5"
        className="lj-round"
        d="M14.7,22.2
        l-9.2-1.6c-0.4-0.1-0.7-0.5-0.6-0.9L7.1,6.9c0-0.2,0.1-0.4,0.3-0.6l4-4.8l10.3,1.8l-3.5,4.9c-0.1,0.2-0.2,0.4-0.2,0.6l-2.2,12.8
        C15.5,22,15.1,22.2,14.7,22.2z"
      />
      <rect
        x="5.8"
        y="10.4"
        transform="matrix(0.9848 0.1736 -0.1736 0.9848 2.6766 -1.7459)"
        fill="#FFFFFF"
        stroke="#1D64CC"
        strokeWidth="1.5"
        width="10.9"
        height="8"
      />
      <rect
        x="16.7"
        y="11.9"
        transform="matrix(0.9848 0.1736 -0.1736 0.9848 3.0456 -3.1114)"
        fill="#FFFFFF"
        stroke="#1D64CC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        width="5.3"
        height="8"
      />
    </g>
    <g>
      <g>
        <path fill="#FFFFFF" d="M3.7,22.6c0,0.2,0.2,0.3,0.4,0.3H11c0.2,0,0.3-0.1,0.4-0.3l0.3-2.3H3.3L3.7,22.6z" />
        <polygon fill="#FFFFFF" points="12.8,12 2.2,12 2.3,12.8 12.7,12.8" />
        <path
          fill="#FFFFFF"
          d="M13.4,13.9H1.6c-0.2,0-0.4,0.2-0.4,0.5l1,4.5c0,0.2,0.2,0.3,0.4,0.3h9.9c0.2,0,0.3-0.1,0.4-0.3l1-4.5
          C13.9,14.1,13.7,13.9,13.4,13.9z"
        />
        <path
          fill="#FFFFFF"
          d="M13.2,8.7H1.8C1.6,8.7,1.5,8.8,1.4,9l-0.3,1.5c0,0.2,0.1,0.4,0.4,0.4h11.9c0.2,0,0.4-0.2,0.4-0.4L13.6,9
          C13.5,8.8,13.4,8.7,13.2,8.7z"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#1D64CC"
        d="M1.8,8.7h11.4c0.2,0,0.4,0.1,0.4,0.3l0.3,1.5
        c0,0.2-0.1,0.4-0.4,0.4H1.6c-0.2,0-0.4-0.2-0.4-0.4L1.4,9C1.5,8.8,1.6,8.7,1.8,8.7z M0.3,8.8c0.1-0.7,0.8-1.3,1.5-1.3h11.4
        c0.8,0,1.4,0.5,1.5,1.3l0.3,1.5c0.1,0.7-0.3,1.4-1,1.7l-0.1,0.9c0.8,0.2,1.3,1,1.1,1.8l-1,4.5c-0.1,0.6-0.6,1-1.2,1.1l-0.3,2.5
        C12.3,23.5,11.7,24,11,24H4c-0.7,0-1.4-0.6-1.5-1.3l-0.3-2.5c-0.6-0.1-1-0.6-1.2-1.1l-1-4.5c-0.2-0.8,0.3-1.6,1.1-1.8L1,11.9
        c-0.7-0.2-1.1-0.9-1-1.7L0.3,8.8z M2.2,12l0.1,0.7h10.4l0.1-0.7H2.2z M11.7,20.3H3.3l0.3,2.3c0,0.2,0.2,0.3,0.4,0.3H11
        c0.2,0,0.3-0.1,0.4-0.3L11.7,20.3z M1.6,13.9h11.9c0.2,0,0.4,0.2,0.4,0.5l-1,4.5c0,0.2-0.2,0.3-0.4,0.3H2.6
        c-0.2,0-0.3-0.1-0.4-0.3l-1-4.5C1.1,14.1,1.3,13.9,1.6,13.9z"
      />
    </g>
  </svg>
);
