/* eslint-disable import/prefer-default-export */
import React from 'react';

export const IconDineIn = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    width="24px"
    viewBox="0 0 24 24"
    stroke="#1D64CC"
    strokeWidth="1.25"
  >
    <g>
      <path
        fill="none"
        d="M11.2,6C6.1,6,2,7.1,2,8.5c0,0,0,11,9.2,11
    s9.2-11,9.2-11C20.4,7.1,16.3,6,11.2,6z"
      />
      <path
        fill="none"
        d="M17.9,16.1c2,0.7,3.3,1.7,3.3,2.8
    c0,2.1-4.5,3.8-10,3.8s-10-1.7-10-3.8c0-1.1,1.3-2.1,3.3-2.8"
      />
      <path
        fill="none"
        d="M20.3,9.5c1.4,0.3,2.5,1.6,2.5,3.1
    c0,1.8-1.4,3.2-3.2,3.2c-0.4,0-0.8-0.1-1.2-0.2"
      />
      <path
        fill="none"
        d="M11.2,6c5.1,0,9.2,1.1,9.2,2.5S16.3,11,11.2,11
    S2,9.9,2,8.5S6.1,6,11.2,6"
      />
    </g>
  </svg>
);
