/* eslint-disable import/prefer-default-export */
import React from 'react';

export const IconCT = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48px"
    width="48px"
    viewBox="0 0 48 48"
  >
    <g>
      <rect x="1.5" y="1.5" fill="#FDFCF2" width="45" height="45" />
      <path fill="#1D64CC" d="M45,3v42H3V3H45 M48,0H0v48h48V0L48,0z" />
    </g>
    <path
      fill="#1D64CC"
      d="M17.7,16c0.5,0,0.8,0.3,0.8,1c0,1.3-0.1,2-0.6,2.4c-0.7,0.6-2.4,1-3.7,1c-4,0-7.1-2.6-7.1-6.7
      s3.2-6.8,7.3-6.8c1.4,0,2.6,0.3,3.2,0.7c0.6,0.4,0.7,0.8,0.7,1.4v0.8c0,0.8-0.2,1.3-0.9,1.3c-0.7,0-1-0.9-1.6-1.7
      c-0.4-0.5-1-0.9-2-0.9c-2.5,0-3.5,2.7-3.5,4.9c0,2.2,0.8,5.4,3.7,5.4C16.6,18.8,16.5,16,17.7,16z"
    />
    <path
      fill="#1D64CC"
      d="M40.2,31.7c-0.3,0-0.5-0.1-0.8-0.8c-0.2-0.4-0.6-1.1-1.1-1.4c-0.4-0.2-0.8-0.3-1.2-0.3
      c-0.5,0-0.5,0.2-0.5,0.5v7.4c0,0.9,0,1.6,0.2,2c0.3,0.9,1.1,0.7,1.1,1.3c0,0.3-0.3,0.5-0.7,0.5H33c-0.4,0-0.7-0.2-0.7-0.5
      c0-0.5,0.7-0.4,1.1-1.2c0.2-0.4,0.2-1,0.2-2v-7.4c0-0.3,0-0.5-0.5-0.5c-0.4,0-0.8,0.1-1.2,0.3c-0.6,0.3-0.9,1-1.1,1.4
      c-0.3,0.7-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.2-0.8-0.7c0-0.3,0-1.1,0.1-1.6c0-0.4,0-0.8,0-1.2c0.1-0.5,0.2-0.6,0.9-0.6
      c0.8,0,2.2,0.1,3.7,0.1h2.6c1.5,0,2.9-0.1,3.7-0.1c0.6,0,0.8,0.1,0.9,0.6c0,0.5,0,0.8,0,1.3c0,0.5,0.1,1.1,0.1,1.5
      C41,31.5,40.7,31.7,40.2,31.7z"
    />
    <g>
      <path
        fill="#4C8BE6"
        d="M6.7,40c-0.3,0-0.6-0.1-0.8-0.4c-0.5-0.5-0.5-1.2,0-1.7c1.5-1.5,3.2-1.4,4.5-1.4c1.2,0.1,1.9,0.1,2.7-0.7
        c0.7-0.7,0.7-1.4,0.7-2.6c-0.1-1.3-0.1-2.9,1.4-4.5c1.5-1.5,3.2-1.4,4.5-1.4c1.2,0.1,1.9,0.1,2.6-0.7c0.7-0.7,0.7-1.4,0.7-2.7
        c-0.1-1.3-0.1-2.9,1.4-4.5c1.5-1.5,3.2-1.4,4.5-1.4c1.2,0.1,1.9,0.1,2.6-0.7c0.7-0.7,0.7-1.4,0.7-2.7c-0.1-1.3-0.1-2.9,1.4-4.5
        C34.9,8.9,36.5,9,37.8,9c1.2,0.1,1.9,0.1,2.7-0.7c0.5-0.5,1.2-0.5,1.7,0c0.5,0.5,0.5,1.2,0,1.7c-1.5,1.5-3.2,1.4-4.5,1.4
        c-1.2-0.1-1.9-0.1-2.7,0.7c-0.7,0.7-0.7,1.4-0.7,2.7c0.1,1.3,0.1,2.9-1.4,4.5c-1.5,1.5-3.2,1.4-4.5,1.4c-1.2-0.1-1.9-0.1-2.6,0.7
        c-0.7,0.7-0.7,1.4-0.7,2.6c0.1,1.3,0.1,2.9-1.4,4.5c-1.5,1.5-3.2,1.4-4.5,1.4c-1.2-0.1-1.9-0.1-2.6,0.7C16,31.2,16,31.9,16,33.1
        c0.1,1.3,0.1,2.9-1.4,4.5c-1.5,1.5-3.2,1.4-4.5,1.4c-1.2-0.1-1.9-0.1-2.6,0.7C7.3,39.9,7,40,6.7,40z"
      />
    </g>
  </svg>
);
