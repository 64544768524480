const slugify = require('slugify');

/**
 * cafe
 * @description Given the title, creates a cafe path
 */

exports.cafe = function (name) {
  const slug = slugify(name.toLowerCase(), { strict: true });
  return `/cafes/${slug}/`;
};

/**
 * post
 * @description Given the title, creates a post path
 */

exports.post = function (name) {
  const slug = slugify(name.toLowerCase(), { strict: true });
  return `/blog/${slug}`;
};

/**
 * page
 * @description Given the title, creates a page path
 */

exports.page = function (name) {
  const slug = slugify(name.toLowerCase(), { strict: true });
  return `/pages/${slug}`;
};
