import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Header from '../components/header';
import Footer from '../components/footer';
import Menu from '../components/menu';
import MobileNav from '../components/MobileNav';

import '../assets/scss/main.scss';

const Layout = ({ children }) => {
  const [query, setQuery] = useState('');
  const handleInputChange = async (event) => {
    const query = await event.target.value;
    setQuery(query);
    console.log(query);
  };

  const childrenWithProps = React.Children.map(children, child => React.cloneElement(child, { searchQuery: query }));
  return (
    <>
      <Header siteTitle="Come Together - Support Coffee" />
      <MobileNav handleInputChange={handleInputChange} />
      <main className="mainWrapper container">
        <Menu handleInputChange={handleInputChange} />
        <div className="main">{childrenWithProps}</div>
      </main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
