import React from 'react';

import Link from './link';
import { IconCT } from './icons';

const Logo = () => (
  <div className="menu__header x">
    <div className="site-name rel z0 mb15">
      <h1>
        <IconCT />
        {' '}
        ComeTogether
      </h1>
      <Link to="/" className="abs fill z2" />
    </div>
  </div>
);

export default Logo;
