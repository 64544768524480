/* eslint-disable react/no-danger */
/* eslint-disable react/no-unknown-property */
/* eslint-disable import/prefer-default-export */
import React from 'react';

export const IconSponsors = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="1.75"
    fill="transparent"
  >
    <path
      class="st0"
      fill="currentColor"
      strokeWidth=".5"
      d="M12.8,18.8V18c1.7-0.1,3-1,2.9-2.3c0-1.4-0.9-1.9-3.5-2.2C10.6,13.1,10,13,10,12.4c0-0.6,0.7-1,1.9-1
      s1.8,0.4,2.1,0.9c0.1,0.2,0.4,0.4,0.6,0.4l0.3-0.1c0.4-0.1,0.7-0.5,0.5-0.8c-0.4-0.8-1.3-1.4-2.7-1.6V9.5c0-0.4-0.3-0.6-0.6-0.6
      h-0.1c-0.4,0-0.6,0.2-0.6,0.6v0.8c-1.8,0.1-3.1,1.1-3.1,2.3s0.9,1.8,3.5,2.2c1.7,0.2,2.2,0.4,2.2,1.1c0,0.7-0.8,1-2,1
      c-1.2,0-1.9-0.4-2.2-1.1c-0.1-0.2-0.3-0.4-0.6-0.4l-0.3,0.1c-0.4,0.1-0.7,0.5-0.5,0.8c0.4,0.9,1.4,1.7,3.1,1.8v0.7
      c0,0.4,0.3,0.6,0.6,0.6h0.1C12.5,19.4,12.8,19.1,12.8,18.8z"
    />
    <path
      class="st1"
      d="M7.2,5.3l0,1.1c-2,0.4-3.6,2.1-3.6,4.3v8.1c0,2.4,1.9,4.3,4.3,4.3h8.1c2.4,0,4.3-1.9,4.3-4.3v-8.1
      c0-2.1-1.6-3.9-3.6-4.3l0-1.1"
    />
    <path
      class="st1"
      d="M17.2,5.3H6.8c-0.7,0-1.3-0.6-1.3-1.3V4c0-0.7,0.6-1.3,1.3-1.3h10.4c0.7,0,1.3,0.6,1.3,1.3v0.1
      C18.5,4.8,17.9,5.3,17.2,5.3z"
    />
    <path class="st1" d="M10.1,2.7c0-1,0.8-1.9,1.9-1.9s1.9,0.8,1.9,1.9" />
  </svg>
);
